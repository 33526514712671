import { useAuthStore } from '@/store/auth'
import useFetchCustom from '~/services/global-useFetch'

export const useManageJobStore = defineStore('manageJob', () => {
  // state store
  const followedCompanies = ref<any>({})
  const getIsFollowed = (companyId: any) => {
    return followedCompanies.value[companyId] ?? false
  }
  const isSave = ref<any>({})
  const getIsSaved = (JobId: any) => {
    return isSave.value[JobId] ?? false
  }

  const isHide = ref<any>({})
  const getIsHide = (JobId: any) => {
    return isHide.value[JobId] ?? false
  }

  const isSubmitCV = ref<any>({})
  const getIsSubmitCv = (JobId: any) => {
    return isSubmitCV.value[JobId] ?? false
  }

  const isApplied = ref<any>({})
  const getIsApplied = (JobId: any) => {
    return isApplied.value[JobId] ?? false
  }

  // set auth
  const authStore = useAuthStore()
  const token = authStore.token

  // save job
  const saveJob = async (Id: string) => {
    try {
      if (!token) {
        authStore.LoginModal(true)
        // return { success: false, message: 'Missing authentication' }
      }
      const { data, statusCode } = await useFetchCustom(
        '/seeker-save-job-web'
      ).post({
        JobId: Id
      })
      if (statusCode.value === 201) {
        isSave.value[Id] = !isSave.value[Id]
        return { success: true, message: 'Job saved successfully' }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } catch (e) {
      throw e
    }
  }

  // hide job
  const hideJob = async (Id: string) => {
    try {
      if (!token) {
        authStore.LoginModal(true)
        return { success: false, message: 'Missing authentication' }
      }
      const { data, statusCode } = await useFetchCustom(
        '/seeker-hide-job-web'
      ).post({
        JobId: Id
      })
      if (statusCode.value === 201) {
        isHide.value[Id] = !isHide.value[Id]
        return { success: true, message: 'Job hidden successfully' }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } catch (e) {
      throw e
    }
  }

  // follow company
  const followCompany = async (employerId: string) => {
    try {
      if (!token) {
        authStore.LoginModal(true)
        return { success: false, message: 'Missing authentication' }
      }
      const body = {
        empId: employerId,
        token: token
      }
      const { data, statusCode } = await useFetchCustom(
        '/add-seeker-follow-employer-web'
      ).post(body)
      if (statusCode.value === 201) {
        followedCompanies.value[employerId] =
          !followedCompanies.value[employerId]
        return { success: true, message: 'Company followed successfully' }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } catch (e) {
      throw e
    }
  }

  // Submit CV
  const submitCvCompany = async (employerId: string) => {
    try {
      if (!token) {
        authStore.LoginModal(true)
        return { success: false, message: 'Missing authentication' }
      }
      const body = {
        empId: employerId,
        token: token
      }
      const { data, statusCode } = await useFetchCustom(
        '/seeker-submit-cv-employer-web'
      ).post(body)
      if (statusCode.value === 201 || 204) {
        isSubmitCV.value = true
        return { success: true, message: 'CV submitted successfully' }
      } else {
        isSubmitCV.value = false
      }
    } catch (e) {
      isSubmitCV.value = false
      throw e
    }
  }

  //Share Job
  const shareAPI = async (_id: any, result: string) => {
    try {

      const body = { _id, token }
      if (result === 'job_detail') {
        const { data, statusCode } = await useFetchCustom('/share-job-web').post(body)
        if (statusCode.value === 201) {
          return {
            success: true,
            message: 'Share successfully'
          }
        }
      } else if (result === 'company-profile') {
        const { data, statusCode } = await useFetchCustom('/share-employer-web').post(body)
        if (statusCode.value === 201) {
          return {
            success: true,
            message: 'Share successfully'
          }
        }
      } else {
        const { data, statusCode } = await useFetchCustom('/share-blog-web').post(body)
        if (statusCode.value === 201) {
          return {
            success: true,
            message: 'Share successfully'
          }
        }
      }

    } catch (e) {
      throw e
    }
  }
  return {
    isSave,
    getIsSaved,
    getIsFollowed,
    isHide,
    getIsHide,
    isSubmitCV,
    getIsSubmitCv,
    isApplied,
    getIsApplied,
    saveJob,
    hideJob,
    followCompany,
    submitCvCompany,
    shareAPI
  }
})
